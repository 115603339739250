import React from "react";

import Layout from "../../components/Layout";
import PodcastRoll from "../../components/PodcastRoll";
import { BLUE } from "../../config/colors";

export default class PodcastIndexPage extends React.Component {
	render() {
		return (
			<Layout>
				<div
					className="full-width-image-container margin-top-0"
					style={{
						backgroundImage: `url('/img/sunset.gif')`,
						backgroundPositionY: "-275px",
						backgroundRepeat: "no-repeat",
					}}
				>
					<h1
						className="has-text-weight-bold is-size-1"
						style={{
							backgroundColor: BLUE,
							color: "white",
							padding: "15px 30px",
						}}
					>
						Recent Podcasts
					</h1>
				</div>
				<section className="section">
					<div className="container">
						<div className="content">
							<PodcastRoll />
						</div>
					</div>
				</section>
			</Layout>
		);
	}
}
